(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/users/assets/javascripts/map-user.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/users/assets/javascripts/map-user.js');
'use strict';

const _excluded = ["userId", "editorUserId"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }
const {
  getDisplayName
} = svs.utils.products;
const roleMapping = {
  10: 'GAME_CREATOR',
  20: 'GAME_ADMIN',
  30: 'ADMIN',
  40: 'OWNER'
};
const relationMapping = {
  1: 'employee',
  2: 'external'
};
function mapUser(user, otherProps) {
  const {
      userId,
      editorUserId
    } = user,
    rest = _objectWithoutProperties(user, _excluded);
  const userName = otherProps && otherProps.retailerId ? otherProps.retailerId + user.userAlias : user.userAlias;
  return _objectSpread(_objectSpread(_objectSpread({}, rest), otherProps), {}, {
    role: roleMapping[user.role] || 'TERMINAL',
    relation: relationMapping[user.type],
    userName,
    assignedContainers: (user.assignedContainers || []).map(mapContainer),
    name: user.name || user.userAlias,
    userId: userId || editorUserId
  });
}
function mapContainer(container) {
  return _objectSpread(_objectSpread({}, container), {}, {
    productName: getDisplayName(container.productId)
  });
}
setGlobal('svs.components.marketplaceRetailAdminData.users.mapUser', mapUser);
setGlobal('svs.components.marketplaceRetailAdminData.users.mapContainer', mapContainer);

 })(window);