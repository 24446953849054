(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/users/assets/javascripts/users-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/users/assets/javascripts/users-actions.js');

'use strict';

const _excluded = ["isDirty"];
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  userSession
} = svs.core;
const {
  services,
  mapUser
} = svs.components.marketplaceRetailAdminData.users;
const {
  showDialog
} = svs.components.marketplaceData.dialogWrapper.actions;
const {
  getUserById
} = svs.components.marketplaceRetailAdminData.selectors;
const LOAD_USERS_INIT = 'USER/LOAD_USERS_INIT';
const LOAD_USERS_SUCCESS = 'USER/LOAD_USERS_SUCCESS';
const LOAD_USERS_FAILED = 'USER/LOAD_USERS_FAILED';
const SET_CURRENT_USER = 'USER/SET_CURRENT_USER';
const SET_CURRENT_USER_FAILED = 'USER/SET_CURRENT_USER_FAILED';
const loadUsers = populateEditUser => (dispatch, getState) => {
  dispatch({
    type: LOAD_USERS_INIT
  });
  async.parallel([services.loadTerminalUsers, services.loadUsers], (err, result) => {
    if (err) {
      dispatch({
        type: LOAD_USERS_FAILED,
        err
      });
    } else {
      const [terminalUsers, editors] = result;

      const {
        groupId,
        retailerId
      } = getState().EditorSession;
      const users = editors.map(editor => {
        const user = terminalUsers.find(user => editor.editorUserId === user.userId);
        return mapUser(editor ? _objectSpread(_objectSpread(_objectSpread({}, user), editor), {}, {
          retailerId,
          isIncompleteUser: !user
        }) : user, {
          groupId,
          retailerId
        });
      });

      const onlyTerminalUser = terminalUsers.map(user => {
        if (users.find(_ref => {
          let {
            userId: userUserId
          } = _ref;
          return userUserId === user.userId;
        })) {
          return null;
        }
        return user;
      });
      onlyTerminalUser.forEach(user => {
        if (user) {
          users.push(mapUser(user, {
            groupId,
            retailerId
          }));
        }
      });
      if (populateEditUser) {
        const user = users.find(_ref2 => {
          let {
            userId
          } = _ref2;
          return userId === populateEditUser;
        });
        if (!user) {
          dispatch(showDialog({
            title: 'Användaren kunde inte hittas',
            type: 'error'
          }));
          dispatch({
            type: SET_CURRENT_USER_FAILED,
            userId: populateEditUser,
            error: "Could not found user, userId: ".concat(populateEditUser)
          });
        } else {
          dispatch({
            type: SET_CURRENT_USER,
            model: user ? {
              name: user.name,
              email: user.email || '',
              role: user.role,
              relation: user.relation || '',
              groupId: user.groupId,
              editorId: user.editorId,
              userId: user.userId
            } : null
          });
        }
      }
      dispatch({
        type: LOAD_USERS_SUCCESS,
        users
      });
    }
  });
};
const setCurrentUser = userId => (dispatch, getState) => {
  const user = getUserById(getState(), userId);
  dispatch({
    type: SET_CURRENT_USER,
    model: {
      name: user.name,
      email: user.email || '',
      role: user.role,
      relation: user.relation || '',
      groupId: user.groupId,
      userId: user.userId,
      editorId: user.editorId
    }
  });
};
const LOGOUT_FAILURE = 'USER/LOGOUT_FAILURE';
const LOGOUT_INIT = 'USER/LOGOUT_INIT';
const LOGOUT_SUCCESS = 'USER/LOGOUT_SUCCESS';
const logout = () => dispatch => {
  dispatch({
    type: LOGOUT_INIT
  });
  userSession.logout(() => {
    dispatch({
      type: LOGOUT_SUCCESS
    });
  }, () => {
    dispatch({
      type: LOGOUT_FAILURE
    });
  });
};
const USER_STOP_EDIT = 'USER/STOP_EDIT';
const stopEdit = () => ({
  type: USER_STOP_EDIT
});
const USER_EDIT_NAME = 'USER/EDIT_NAME';
const USER_EDIT_EMAIL = 'USER/EDIT_EMAIL';
const USER_EDIT_ROLE = 'USER/EDIT_ROLE';
const USER_EDIT_RELATION = 'USER/EDIT_RELATION';
const editName = name => ({
  type: USER_EDIT_NAME,
  name
});
const editEmail = email => ({
  type: USER_EDIT_EMAIL,
  email
});
const editRole = role => ({
  type: USER_EDIT_ROLE,
  role
});
const editRelation = relation => ({
  type: USER_EDIT_RELATION,
  relation
});
const USER_EDIT_INIT = 'USER/USER_EDIT_INIT';
const USER_EDIT_SUCCESS = 'USER/USER_EDIT_SUCCESS';
const USER_EDIT_FAILED = 'USER/USER_EDIT_FAILED';
const editUser = () => (dispatch, getState) => {
  dispatch({
    type: USER_EDIT_INIT
  });
  services.updateUser(_objectSpread({}, getState().EditUser), (error, editorId) => {
    if (error) {
      dispatch({
        type: USER_EDIT_FAILED
      });
      dispatch(showDialog({
        title: 'Det gick inte att spara ändringarna just nu. Försök igen om en stund.',
        type: 'error'
      }));
    } else {
      const _getState$EditUser = getState().EditUser,
        {
          isDirty
        } = _getState$EditUser,
        withoutIsDirty = _objectWithoutProperties(_getState$EditUser, _excluded);
      dispatch({
        type: USER_EDIT_SUCCESS,
        user: _objectSpread(_objectSpread({}, withoutIsDirty), editorId ? {
          editorId
        } : {})
      });
      dispatch(showDialog({
        title: 'Ändringarna är sparade',
        icon: 'checkmark'
      }));
    }
  });
};
const USER_DELETE_INIT = 'USER/USER_DELETE_INIT';
const USER_DELETE_SUCCESS = 'USER/USER_DELETE_SUCCESS';
const USER_DELETE_FAILED = 'USER/USER_DELETE_FAILED';
const deleteEditor = (userIdAsProp, onSuccessCb) => (dispatch, getState) => {
  var _getState$EditUser2, _getState$Users;
  const editorId = ((_getState$EditUser2 = getState().EditUser) === null || _getState$EditUser2 === void 0 ? void 0 : _getState$EditUser2.editorId) || ((_getState$Users = getState().Users) === null || _getState$Users === void 0 || (_getState$Users = _getState$Users.users) === null || _getState$Users === void 0 || (_getState$Users = _getState$Users.find(user => user.userId === userIdAsProp.userId)) === null || _getState$Users === void 0 ? void 0 : _getState$Users.editorId);
  dispatch({
    type: USER_DELETE_INIT
  });
  services.deleteEditor(editorId, error => {
    if (error) {
      dispatch({
        type: USER_DELETE_FAILED
      });
      dispatch(showDialog({
        title: 'Det gick inte att ta bort användaren just nu. Försök igen om en stund',
        type: 'error'
      }));
    } else {
      dispatch({
        type: USER_DELETE_SUCCESS,
        editorId
      });
      dispatch(showDialog({
        title: 'Användaren har blivit borttagen',
        icon: 'checkmark'
      }));
      if (onSuccessCb) {
        onSuccessCb();
      }
    }
  });
};

setGlobal('svs.components.marketplaceRetailAdminData.users.actions', {
  LOAD_USERS_INIT,
  LOAD_USERS_SUCCESS,
  LOAD_USERS_FAILED,
  loadUsers,
  LOGOUT_FAILURE,
  LOGOUT_INIT,
  LOGOUT_SUCCESS,
  logout,
  USER_STOP_EDIT,
  stopEdit,
  USER_EDIT_NAME,
  editName,
  USER_EDIT_EMAIL,
  editEmail,
  USER_EDIT_ROLE,
  editRole,
  USER_EDIT_RELATION,
  editRelation,
  USER_EDIT_INIT,
  USER_EDIT_SUCCESS,
  USER_EDIT_FAILED,
  editUser,
  USER_DELETE_INIT,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAILED,
  deleteEditor,
  SET_CURRENT_USER,
  SET_CURRENT_USER_FAILED,
  setCurrentUser
});

 })(window);